const socialMappings = [
  {
    partialUrl: "facebook.com",
    type: "Facebook",
  },
  {
    partialUrl: "flickr.com",
    type: "Flickr",
  },
  {
    partialUrl: "instagram.com",
    type: "Instagram",
  },
  {
    partialUrl: "linkedin.com",
    type: "LinkedIn",
  },
  {
    partialUrl: "reddit.com",
    type: "Reddit",
  },
  {
    partialUrl: "snapchat.com",
    type: "Snapchat",
  },

  {
    partialUrl: "tiktok.com",
    type: "TikTok",
  },

  {
    partialUrl: "twitter.com",
    type: "Twitter",
  },

  {
    partialUrl: "youtube.com",
    type: "YouTube",
  },
  {
    partialUrl: "vimeo.com",
    type: "Vimeo",
  },
];

export default socialMappings;
