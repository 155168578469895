import React from "react";
import PropTypes from "prop-types";
import Util from "../Util";
import { Fragment } from "react";

function Accessibility(props = {}) {
  const { className, email, phone, ...rest } = props
  let classN = 'bux-footer__ada'
  if(className) classN += ` ${className}`
  return (
    <div className={classN} {...rest}>
      If you have a disability and experience difficulty accessing this content,
      please contact the Digital Accessibility Center for assistance at{" "}
      {!!email?.href && <Util.Link href={email.href}>
        {email.label || email.href}
      </Util.Link>}{" "}
      {!!phone?.href && <Fragment>
        or{" "}
        <Util.Link href={phone.href}>
          {phone.label || phone.href}
        </Util.Link>
      </Fragment>}
      .
    </div>
  );
}

Accessibility.defaultProps = {
  email: {
    label: "das@osu.edu",
    href: "mailto:das@osu.edu",
  },
  phone: {
    label: "614-292-5000",
    href: "tel:614-292-5000",
  },
};

Accessibility.propTypes = {
  email: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
  phone: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
};

export default Accessibility;
