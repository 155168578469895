import TableOfContents from './components';
import withTableOfContents from './withTableOfContents';
import AnchorHeading from './components/AnchorHeading'
import BaseLink from './components/BaseLink'
import Popover from './components/Popover'
import PopoverButton from './components/PopoverButton'
import Section from './components/Section'
import Sidebar from './components/Sidebar'
import StylizedLink from './components/StylizedLink'

import { TableOfContentsContext as Context, TableOfContentsProvider as Provider, 
    TableOfContentsControllerContext, TableOfContentsControllerProvider,
    TableOfContentsPositioningContext, TableOfContentsPositioningProvider
} from './context'

export const TOC = {
    AnchorHeading,
    BaseLink,
    Popover,
    PopoverButton,
    Section,
    Sidebar,
    StylizedLink,
    Context, 
    Provider,
    Controller: {
        Context: TableOfContentsControllerContext, 
        Provider: TableOfContentsControllerProvider
    },
    Positioning: {
        Context: TableOfContentsPositioningContext, 
        Provider: TableOfContentsPositioningProvider
    }
}

export {
    TableOfContents,
    withTableOfContents
}