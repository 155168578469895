import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useMutationObserver from "./useMutationObserver";
import { scrollToHash } from "../utilities/domMutations";

const useScrollToHash = () => {
  const { hash, state } = useLocation();
  const { ref, observedElement = {} } = useMutationObserver({
    selector: hash && hash.substring(1),
  });
  const { offsetTop, node, id } = observedElement
  const disableScroll = state?.disableScroll;

  useEffect(() => {
    if(!disableScroll && id) {
      scrollToHash(document, id)
    }
  }, [disableScroll, id, node, offsetTop]);

  return {
    ref,
  };
};

export default useScrollToHash;