import React from "react";
import PropTypes from "prop-types";
import socialMappings from "../mapping";
import SocialLink from "./Link";

const convertLinkToProperties = (
  href = "",
  type = "",
  icon = "",
  hiddenAccessibleText = ""
) => {
  const { type: matchedType = "", hiddenAccessibleText: hat = "" } =
    socialMappings.find(({ partialUrl }) => href.includes(partialUrl)) || {};

  let properties = {
    icon,
    hiddenAccessibleText,
    type,
  };
  if (!matchedType) {
    return properties;
  }
  if (!properties.icon) {
    properties.icon = matchedType.toLowerCase();
  }
  if (!properties.hiddenAccessibleText) {
    properties.hiddenAccessibleText =
      hat || `${matchedType} profile — external`;
  }
  if (!properties.type) {
    properties.type = matchedType;
  }

  return properties;
};

const SocialListItem = (props = {}) => {
  const { href, className, type, icon, hiddenAccessibleText, ...rest } = props;
  let classN = "bux-social-links__link";
  if (className) classN += ` ${className}`;
  let {
    icon: ico,
    type: icoType,
    hiddenAccessibleText: hat,
  } = convertLinkToProperties(href, type, icon, hiddenAccessibleText);

  return (
    <li className={`bux-social-links__item bux-social-links__item--${icoType}`} {...rest}>
      <SocialLink href={href} type={icoType} icon={ico} hiddenAccessibleText={hat} />
    </li>
  );
};

let types = socialMappings.map((social) => social.type.toLowerCase());

SocialListItem.proptypes = {
  type: PropTypes.oneOf(types).string,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string,
  hiddenAccessibleText: PropTypes.string,
};

export default SocialListItem