import { Mui } from "../libraries/material";
import React, { Fragment } from "react";
import PropTypes from 'prop-types'
import Table from "./Table";
import TableFilters from "./Filters";

function FilteredTable(props = {}) {
  const { rows, columns, filters: reviewFilters, rowActions } = props;
  const [rowData, setRowData] = React.useState(rows || []);

  const manageRows = (newFiltrationState) => {
    const searchValue = newFiltrationState?.searchValue;
    const newFilters = newFiltrationState?.filters;
    let filteredRows = [...rows];
    let filterEntries = Object.entries(newFilters || {});

    filterEntries.forEach((filter) => {
      const key = filter[0];
      const values = filter[1];
      if (values?.length) {
        filteredRows = filteredRows.filter((row) => {
          const matchingField = row[key];
          const allowedByFilter =
            matchingField && values.includes(matchingField);
          return allowedByFilter;
        });
      }
    });

    if(searchValue) {
      filteredRows = filteredRows.filter(row => {
        const rowValues = Object.values(row || {})
        const stringifiedQuery = rowValues.join(" ").toUpperCase()

        return stringifiedQuery.includes(searchValue.toUpperCase())
      })
    }
    setRowData(filteredRows)
  };

  return (
    <Fragment>
      <TableFilters onFiltrationUpdate={manageRows} filters={reviewFilters} />
      <Mui.Box className="margin-top-2">
        <Table columns={columns} rows={rowData} rowActions={rowActions} />
      </Mui.Box>
    </Fragment>
  );
}

FilteredTable.defaultProps = {
  rows: [],
};

FilteredTable.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        field: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        values: PropTypes.array
    }))
}

export default FilteredTable;
