import React from "react"
import graySvg from "../assets/osu-horiz-gray.svg"
import whiteSvg from "../assets/osu-horiz-white.svg"

const Logo = (props = {}) => {
    const { src, white, darkMode, alt } = props
    const wordmarkImage = (!darkMode || white)  ? graySvg : whiteSvg
    
    return (
        <div className="bux-footer__logo">
        <a href="http://osu.edu" target="_blank" rel="noopener noreferrer">
          <img
            src={src || wordmarkImage}
            alt={alt || "The Ohio State University"}
          />
        </a>
      </div>
    )
}

export default Logo;
