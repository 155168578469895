import React from "react";
import PropTypes from "prop-types";
import socialMappings from "../mapping";

const SocialLink = (props = {}) => {
  const { className, type, icon, hiddenAccessibleText, ...rest } = props;

  let classN = "bux-social-links__link";
  if (className) classN += ` ${className}`;
  return (
    <a target="_blank" rel="noopener noreferrer" className={classN} {...rest}>
      <span className="visually-hidden">{hiddenAccessibleText}</span>
      <i className={`icon icon-${icon}`}></i>
    </a>
  );
};

let types = socialMappings.map((social) => social.type.toLowerCase());

SocialLink.proptypes = {
  type: PropTypes.oneOf(types).isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  hiddenAccessibleText: PropTypes.string.isRequired,
};

export default SocialLink