import React from "react";
import PropTypes from 'prop-types';
import SocialListItem from "./ListItem";

function List(props = {}) {
  const { links, theme } = props;

  return (
    <div className="bux-footer__social">
      <ul className={`bux-social-links bux-social-links--${theme}`}>
        {links.map((link, index) => {
          if (typeof link === "string" && link.includes("http")) {
            return (
              <SocialListItem href={link} key={`footer-social-link-${index}`} />
            );
          } else if (link?.href) {
            return (
              <SocialListItem {...link} key={`footer-social-link-${index}`} />
            );
          }
          return <Fragment />;
        })}
      </ul>
    </div>
  );
}

List.defaultProps = {
  links: [],
  theme: "",
};

List.propTypes = {
  theme: PropTypes.oneOf(["white", "dark", "light"]).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        type: PropTypes.string,
        href: PropTypes.string.isRequired,
        icon: PropTypes.string,
        hiddenAccessibleText: PropTypes.string,
      }),
    ])
  ),
};

export default List;
