import { useEffect, useRef, useState } from "react";

const parseMutations = (
  mutations = [],
  selector,
  previousSelectedElementId
) => {
  for (let mutation of mutations) {
    const getId = (el) => el && (typeof el.getAttribute === 'function') && el.getAttribute("id");
    let matchedNode = null
    if(!selector) {
      return null
    } 
    for (let addedNode of mutation.addedNodes) {
      const newNodeId = getId(addedNode)
      if (
        (previousSelectedElementId !== newNodeId) &&
        (newNodeId === selector)
      ) {
        matchedNode = addedNode;
      }
    }

    if(!matchedNode) {
      const refreshedElementFromDocument = document.getElementById(selector)
      if(refreshedElementFromDocument && (getId(refreshedElementFromDocument) !== previousSelectedElementId)) {
        matchedNode = refreshedElementFromDocument
      }
    }

    return matchedNode
  }
};

const useMutationObserver = (options = {}) => {
  const { selector = '' } = options;
  const [node, setNode] = useState(null);
  const [selectedElement, updateSelectedElement] = useState();

  const observer = useRef(
    new window.MutationObserver((mutations, observer) => {
      updateSelectedElement((previousSelectedElement) => {
        const matchingNewNode = parseMutations(mutations, selector, previousSelectedElement && previousSelectedElement.getAttribute("id"))
        
        if(matchingNewNode) {
          observer.disconnect();
          return matchingNewNode;
        }
      })
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    if(currentObserver) {
      currentObserver.disconnect()
    }
    const currentElementId = selectedElement && selectedElement.getAttribute('id')
    
    if(selector && !(currentElementId && (currentElementId === selector))) {
      if (node) {
        currentObserver.observe(node, {
          childList: true,
          subtree: true
        });
      }
  
      return () => currentObserver && currentObserver.disconnect();
    }

  }, [node, selectedElement, selector]);

  return {
    ref: setNode,
    observedElement: {
      node: selectedElement,
      offsetTop: selectedElement && selectedElement.offsetTop,
      id: selectedElement && selectedElement.getAttribute('id')
    }
  }
};

export default useMutationObserver;