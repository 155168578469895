import React from 'react';
import PropTypes from 'prop-types';
import useDarkMode from "../hooks/useDarkMode";

const withDarkMode = (Component) => {
  return function _withDarkMode(props = {}) {
    const { darkMode: propsDarkMode, ...rest } = props
    const componentName =
      Component.displayName || Component.name || "Component";
    const { darkMode } = useDarkMode(propsDarkMode);

    _withDarkMode.displayName = `withDarkMode(${componentName})`;
    _withDarkMode.propTypes = {
      darkMode: PropTypes.bool
    }
    
    return <Component {...rest} darkMode={darkMode}  />;
  };
};

export default withDarkMode;
