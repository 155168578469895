import React from "react";

const Address = (props = {}) => {
  const {
    orgName,
    addressLine1,
    addressLine2,
    city,
    state,
    zip
  } = props;

  return (
      <div className="bux-footer__contact bux-footer__contact--address">
        {orgName && <p className="bux_footer__site-name">{orgName}</p>}
        {addressLine1 && <p className="bux_footer__address">{addressLine1}</p>}
        {addressLine2 && <p className="bux_footer__address">{addressLine2}</p>}
        {city && state && zip && (
          <p className="bux_footer__address">
            {city}, {state} {zip}
          </p>
        )}
      </div>
  );
};

Address.defaultProps = {
  orgName: "Office of Technology and Digital Innovation",
  addressLine1: "100 Mount Hall",
  addressLine2: "1050 Carmack Road",
  city: "Columbus",
  state: "OH",
  zip: "43210",
};

export default Address;
