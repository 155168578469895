import React from "react";
import PropTypes from "prop-types";

function Link(props = {}) {
  const { href, className, ...rest } = props;
  let classN = `bux-link`;
  if (className) classN += ` ${className}`;
  return <a className={classN} href={href} {...rest} />;
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Link;
