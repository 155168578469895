import React from "react";

function NavLinksListItem(props = {}) {
  const { href, children } = props;
  return (
    <li className="bux-osu-nav__link">
      <a href={href}>{children}</a>
    </li>
  );
}

export default NavLinksListItem;
