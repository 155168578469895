import { createContext } from 'react';

export const PAGE_HEADING_DEFAULT_VALUE = {
    heading: '',
    applicationName: "The Ohio State University",
    pageTitle: "",
    options: {
        setHeading: () => {}, 
        setApplicationName: () => {}, 
        setOptions: () => {},
        setPageTitle: () => {}
    }
}

export const PageHeadingContext = createContext(PAGE_HEADING_DEFAULT_VALUE);
export const PageHeadingProvider = PageHeadingContext.Provider;